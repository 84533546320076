.blogSubTitle {
    margin: 25px 75px;

    font-size: 1.5rem;
}

.blogContent {
    margin: 25px 75px;

    text-align: justify;
    font-size: 1.2rem;
}

.blogSignature {
    margin-bottom: 0px;
    margin-left: 75px;
    margin-right: 75px;

    text-align: justify;
    font-style: italic;
}

.blogTags {
    margin-top: 0px;

    margin-left: 75px;
    margin-right: 75px;

    text-align: justify;
    font-style: italic;
}

.blogImage {
    width: 100%;
    max-height: 700px;

    border-radius: 50px;
}

.blogParagraph {
    margin-top: 5px;
    margin-bottom: 5px;
}

.indent {
    display: inline-block;
    margin-left: 50px;
}

.blogButtonLeft {
    width: 100% !important;
    height: auto !important;

    padding-left: 25px !important;
    text-align: left !important;
}

.blogButtonRight {
    width: 100% !important;
    height: auto !important;

    padding-right: 25px !important;
    text-align: right !important;
}

.blogMenuTitle {
    margin-left: 10px;

    text-align: left;

    font-size: 1.2rem;
}

.blogCard {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px;

    width: 100%;
    min-height: 75px;
}

.cardBlogImage {
    margin-bottom: 25px;

    width: 100%;
    height: 200px;

    border-radius: 5px;
}

.popularCard {
    margin-left: 0px;
    margin-right: 0px;
}

@media only screen and (max-width: 990px) {
    .blogMenuTitle {
        margin-left: 75px;
    }
    .blogButtonLeft {
        margin-bottom: 5px;
    }    
    .blogButtonRight {
        margin-top: 5px;
    }
}

@media only screen and (max-width: 767px) {
    .blogParagraph {
        margin-left: -75px;
        margin-right: -75px;
    }
}

@media only screen and (max-width: 600px) {
    .blogParagraph {
        margin-left: -25px;
        margin-right: -25px;
    }
}

@media only screen and (max-width: 676px) {
    .popularCard {
        margin-left: 50px;
        margin-right: 50px;
    }    
}