.meditatiiContainer {
    margin-bottom: 25px;

    border-style: groove;
    border-width: 2px;
    border-radius: 20px;
    border-color: rgb(31, 30, 30);

    height: auto;

    background-color: #eeeeee6f;
}

.meditatiiContainerDown {
    margin-top: 25px;
}

.meditatiiTitle {
    margin: 0px;
    padding-top: 10px;

    font-size: 22px;
    font-weight: 600;
}

.meditatiiPrice {
    margin: 0px;
    margin-bottom: 10px;

    font-size: 16;
    font-style: italic;
}

.meditatiiDescription{
    margin: 0% 5% 0% 5%;

    text-align: justify;
    font-size: 18px;
}

.bold{
    font-weight: bold;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.meditatiiButton {
    margin-top: 30px;
    margin-bottom: 10px;

    font-size: 20px !important;

    width: 250px;
    height: 50px;

    text-align: center;
}

@media only screen and (max-width: 990px) {
    .meditatiiContainerDown {
        margin-top: 0px;
    }
}