.navbar {
    margin: 25px 50px 0px 50px !important;
    padding: 10px 50px 10px 50px !important;

    border-radius: 50px;
}

.navbar-brand {
    margin-right: 0px !important;
}

.navbarCenter {
    display: flex;
    justify-content: center;
    flex-grow: 1;

    font-size: 18px;
}

.navbarCenter .nav-link {
    margin-left: 15px;
    margin-right: 15px;
}

.colorForDropdown > .dropdown-toggle {
    color: white;
}

.header-img {
    width: 100%;

    text-align: center;

    display: grid;
    place-items: center;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bold-text {
    padding: 0;

    font-weight: bold !important;
    display: inline-block;
    position: relative;

    color: #ffffff !important;
}

.bold-text::after {
    content: "";
    position: absolute;

    bottom: 0;
    width: 100%;

    border-radius: 6px;
    border-bottom: 6px solid rgb(201, 201, 201);
}

.nav-text {
    padding: 0;
    font-weight: 400 !important;

    color: #ffffff !important;
}

.nav-text:hover {
    padding-bottom: 0;
    font-weight: 400 !important;

    border-radius: 6px;
    border-bottom: 6px solid rgb(201, 201, 201);
}

.nav-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-bottom: 0px solid;
}

.nav-dropdown-text > a.nav-link {
    color: white !important;
}

.navbarDropdopownText {
    font-weight: 500 !important;

    color: black !important;
}

.navbarDropdopownText:hover {
    color: red;
}

.navbar-nav {
    justify-content: center !important;
    align-items: center !important;

    padding-top: 10px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 1200px) {
    .navbarCenter .nav-link {
        margin-right: 0px;
    }
}

@media only screen and (max-width: 550px) {
    .navbar {
        margin-left: 25px !important;
        margin-right: 25px !important;

        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}

@media only screen and (max-width: 500px) {
    .navbar {
        margin-left: 10px !important;
        margin-right: 10px !important;

        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}