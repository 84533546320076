.footerIcons {
    margin: 2px;
    
    width: 30px;
    height: 30px;
}

.footerIcons:hover {
    transform: scale(1.05);
}

.footerArrow {
    width: 50px;
}

.footerArrow:hover {
    transform: scale(1.05);
}

.footerUp {
    margin-top: -30px;
}

.textUp {
    margin-top: -75px;
    margin-left: 100px;
    margin-right: 100px;

    color: gray;
}

.footerText {
    margin-right: 50px;
    text-align: justify;
}

@media only screen and (max-width: 800px) {
    .footerUp {
        margin-top: -25px !important;
    }

    .textUp {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .footerText {
        margin-right: 0px;
        text-align: center;
    }
}