.principalContainer {
    margin-top: 125px;
    padding: 10px;
    padding-bottom: 30px;

    text-align: justify;

    font-size: 16px;
    font-weight: 500;

    border-radius: 20px;
}

.hr {
    border-top: 3px solid rgb(0, 0, 0);
    border-radius: 20px;
}