.titleParcurs {
    margin: 0px;
    padding-top: 0px;

    text-align: center;
    font-size: 30px !important;
    font-weight: bold;
}

.imgParcurs {
    border: 5px solid gray !important;
    position: relative;

    width: 150px;
    height: 150px;
}

.rightText {
    text-align: justify;
}

.leftText {
    text-align: justify;
}

.textParcuers {
    margin-left: 10px;
}

.titleParcurs {
    padding: 0px 0px 10px 10px;

    font-size: 22px;
    font-weight: bold;
}

.periodParcurs {
    padding: 0px 0px 10px 10px;

    font-size: 16px;
    font-weight: 700;
    font-style: italic;
}

.descriptionParcurs {
    padding: 0px 0px 10px 10px;

    font-size: 14px;
}

.verticalLine {
    position: absolute;
    left: 50%;

    height: 100px;
    width: 10px;

    border-left: 5px solid gray;
}

@media only screen and (max-width: 850px) {
    .imgParcurs {
        width: 100px;
        height: 100px;
    }
}

@media only screen and (max-width: 600px) {
    .titleParcurs {
        font-size: 20px;
    }

    .periodParcurs {
        font-size: 16px;
    }

    .descriptionParcurs {
        font-size: 14px;
    }

    .imgParcurs {
        width: 75px;
        height: 75px;
    }
}

.containerWaveDown {
    margin-top: -25px;
}

@media only screen and (max-width: 800px) {
    .containerWaveUp {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 650px) {
    .containerWaveUp {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 500px) {
    .containerWaveUp {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 700px) {
    .containerWaveDown {
        margin-top: -5px;
    }
}

@media only screen and (max-width: 900px) {
    .titleParcurs {
        padding-top: 25px !important;
    }
}