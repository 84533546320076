.principalTitle {
    padding: 30px;

    text-align: justify;

    font-family: 'Consolas';
    font-size: 30px;
    font-weight: bold;

    color: white;
}

.title {
    margin: 0px;
    padding: 100px 0px 0px 0px;

    text-align: center;
    font-size: 32px;
    font-weight: bold;
}

.subTitle {
    margin-top: 0px;
    margin-bottom: 20px !important;

    text-align: center;
    font-style: italic;
    font-size: 20px;
    font-weight: 300;
}

@media (max-width: 400px) {
    .principalTitle {
        font-size: 26px;
    }
}