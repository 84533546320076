.faq-container{
    width: 90%;
}

.faq-title{
    position: relative;
    margin: 0px;
    margin-top: 10px;
    padding: 0px;
    
    text-align: center;
    font-size: 32px;
    font-weight: bolder;

    color: #1A1B2B;
}

.card-header{
    background-color: transparent !important;
    border: 0 !important;
}

.card{
    background-color: white;
    box-shadow: 0px 8px 14px -1px rgba(0, 0, 0, 0.25);
    border-radius: 26px !important;
}

.card-answer{
    margin-left: calc(3.05em);
}

.my-accordion-button {
    border-width: 0;
    background-color: transparent;
}

.accordion-icon {
    width: 1.5em;
}