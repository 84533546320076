.skillsContainerLeft {
    margin-bottom: 20px;

    border-style: groove;
    border-width: 2px;
    border-radius: 50px 20px;
    border-color: rgb(31, 30, 30);

    height: auto;

    background-color: #eeeeee6f;
}

.skillsContainerCenter {
    margin-bottom: 20px;

    border-style: groove;
    border-width: 2px;
    border-radius: 50px;
    border-color: rgb(31, 30, 30);

    height: auto;

    background-color: #eeeeee6f;
}

.skillsContainerRight {
    margin-bottom: 20px;

    border-style: groove;
    border-width: 2px;
    border-radius: 20px 50px;
    border-color: rgb(31, 30, 30);

    height: auto;

    background-color: #eeeeee6f;
}

.skillsTitle {
    padding-top: 10px;

    font-size: 22px;
    font-weight: 600;
}

.skillsDescription{
    margin: 0% 5% 5% 5%;

    text-align: justify;
    font-size: 18px;
}

.fixBottomWave {
    margin-bottom: -150px;
}

@media only screen and (max-width: 1000px) {
    .skillsContainerLeft, .skillsContainerRight {
        border-radius: 50px;
    }
}

@media only screen and (max-width: 1100px) {
    .fixBottomWave {
        margin-bottom: -100px;
    }
}

@media only screen and (max-width: 990px) {
    .fixBottomWave {
        margin-bottom: -50px;
    }
}

@media only screen and (max-width: 600px) {
    .fixBottomWave {
        margin-bottom: 0px;
    }
}