.titleFeedback {
    margin-top: -75px;
    padding: 100px 0px 0px 0px;

    text-align: center;
    font-size: 35px;
    font-weight: bold;
}


.feedbackNameText {
    margin-top: 10px;

    font-size: 20px;
    font-weight: bold;
}

.feedbackDescriptionText {
    margin: 20px 15% 75px 15%;

    font-size: 16px;
    font-style: italic;
}

.stars {
    width: 150px;
    height: auto;
}