.principalImg {
    margin-top: -25px;

    height: 100vh;

    background-image: url("../../assets/photos/PrincipalImg.jpg");
    background-repeat: no-repeat;
    background-position: 50% 20%;
    background-size: cover;
    background-attachment: fixed;
}

.homeContainer {
    height: 80vh;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.name {
    margin-top: 20%;

    font-size: 65px;
    font-weight: bolder;
    font-style: italic;

    color: black;
}

.textEffect {
    font-size: 40px;
    font-weight: 500;

    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);

    color: black;
}

.textEffect {
    margin-top: 25px;
}

.homeButton {
    margin-top: 30px;

    width: 200px;
    height: 50px;

    font-size: 20px !important;

    text-align: center;

    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.homeButton:hover{
    transform: scale(1.1);
}

@media only screen and (max-width: 400px) {
    .name {
        margin-top: 175px;
    }
}