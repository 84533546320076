.contactContainer {
    padding: 20px;
    margin-bottom: 20px;

    border-style: groove;
    border-width: 2px;
    border-color: #1f1e1e;

    height: auto;
    background-color: #eeeeee6f;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactButton {
    margin-top: 20px;

    font-size: 20px !important;

    width: 250px;
    height: 50px;

    text-align: center;
}

.CAPTCHA {
    margin-top: 20px !important;

    display: flex;
    justify-content: center;
    align-items: center;
}

.formAppearance {
    border: 1px solid #4b4b4b !important;

    background-color: gainsboro;
    font-weight: 700 !important;

    color: black !important;
}

.field {
    border-radius: 0px;
    border-color: none;
}

input[type=text], input[type=textarea], input[type=password], input[type=email], .textarea{
    width: 100%;

    margin-left: 5px;
    margin-bottom: 5px;

    display: inline-block;
    
    border: 2px solid #747474 !important;
    border-radius: 5px;
    box-sizing: border-box;
}