.errorPhoto {
    margin-top: 75px;

    width: 500px;
    height: auto;
}

.errorText {
    font-size: 36px;
    font-weight: bolder;
    font-style: italic;
}

@media only screen and (max-width: 600px) {
    .errorPhoto {
        width: 400px;
    }
}

@media only screen and (max-width: 400px) {
    .errorPhoto {
        width: 300px;
    }
}