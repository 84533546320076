.selectProject {
    margin: 25px;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;
}

.selectButton {
    margin: 10px;

    flex: 1 1 auto;

    min-width: 120px;

    color: white !important;
    background-color: black !important;
}

.noSelectButton {
    margin: 10px;

    flex: 1 1 auto;

    min-width: 120px;
}

.subSubTitle {
    margin-bottom: 35px;

    text-align: center;
    font-size: 28px;
    font-weight: bold;
}

.containerPortofoliu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;
    gap: 100px 50px;
    padding: 50px 0px;
}

.containerPortofoliu .cardPortofoliu {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: 350px;
    height: 300px;
    
    border-radius: 20px;
    box-shadow: 0 35px 80px rgba(0,0,0,0.15);
    transition: 0.5s;

    background-color: #fff;
}

.containerPortofoliu .cardPortofoliu:hover {
    height: 400px;
}

.containerPortofoliu .cardPortofoliu .imgBx {
    position: absolute;

    top: 20px;
    width: 320px;
    height: 220px;

    border-radius: 12px;
    overflow: hidden;
    transition: 0.5s;

    background-color: #333;
}

.containerPortofoliu .cardPortofoliu:hover .imgBx {
    top: -100px;
    scale: 0.85;

    box-shadow: 0 15px 45px rgba(0,0,0,0.2);
}

.containerPortofoliu .cardPortofoliu .imgBx img {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
}

.containerPortofoliu .cardPortofoliu .contentPortofoliu {
    position: absolute;

    top: 250px;
    padding: 0 30px;

    width: 100%;
    height: 30px;

    overflow: hidden;
    text-align: center;
    transition: 0.5s;
}

.containerPortofoliu .cardPortofoliu:hover .contentPortofoliu {
    top: 130px;
    height: 250px;
}

.containerPortofoliu .cardPortofoliu .contentPortofoliu h1 {
    font-size: 1.3em;
    font-weight: 700;

    color: var(--clr);
}

.containerPortofoliu .cardPortofoliu .contentPortofoliu p {
    padding-bottom: 0;
    margin-bottom: 0;

    color: #333;
}
.btn {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.btn:hover {
    transform: scale(1.1);
}